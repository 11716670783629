    import React, { useState, useEffect } from "react";
    import { useNavigate, useOutletContext } from "react-router-dom";
    import "./dashboard.css";
    import { useLocation, useHistory } from "react-router-dom";

    const Dashboard = () => {
    
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const [flag, setFlag] = useState(queryParams.get("flag"));
        const navigate = useNavigate();
        
        const [successMessage, setSuccessMessage] = useState(null);

        const [file, setFile] = useState(null);
        const [selectedFileName, setSelectedFileName] = useState("");
        const [showDownloadButtons, setShowDownloadButtons] = useState(false);
        const [showProgressBar, setShowProgressBar] = useState(false);
        const [progress, setProgress] = useState(0);

        
    
        useEffect(() => {
        // const justLoggedIn = sessionStorage.getItem("justLoggedIn");
        const justLoggedIn = localStorage.getItem("justLoggedIn");
        console.log(justLoggedIn);
        if (justLoggedIn === "true") {
            const sessionMessage = "Login Successful!";
            if (sessionMessage) {
                setSuccessMessage(sessionMessage);
                const timer = setTimeout(() => {
                    setSuccessMessage(null);
                    // sessionStorage.removeItem("justLoggedIn");
                    localStorage.removeItem("justLoggedIn");
                }, 2000);
                return () => clearTimeout(timer);
            }
        }
    });

        const handleLogout = () => {
            // sessionStorage.setItem('justLoggedOut', 'true');
            localStorage.setItem('justLoggedOut', 'true');
            navigate('/login');
        };
        
        const handleNaviCompPlain = () => {
            navigate('/complain');
        }

        const handleNaviCompMd5 = () => {
            navigate('/commd5com');
        }

        const handleNaviConvertMd5Plain = () => {
            navigate('/convertplainmd5');
        }
        const handleNaviMerge = () => {
            navigate('/merge');
        }
 

       


        return (
            <div>
            {successMessage && (
                <div className="alert">
                    {successMessage}
                </div>
            )}
   
            <div className="container">
            <div className="log">
              <div></div>
              <div>
                <h2>Dashboard</h2>
              </div>
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
              <a href="#" className="btn-logout" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i></a>
            </div>
      <br></br>
      <br></br>
            <div className="button-container">
              <div className="button-row">
                <button className="button" onClick={handleNaviConvertMd5Plain}>Convert Plain-MD5</button>
                <button className="button" onClick={handleNaviCompMd5}>Compare Plain-MD5</button>
              </div>
              <div className="button-row">
                <button className="button" onClick={handleNaviCompPlain}>Compare Plain-Plain</button>
                {/* <button className="button" onClick={handleNaviMerge}>Merge CSV files</button> */}
                <div></div>
              </div>
            </div>
          </div>
      </div>
          
        );
    };

    export default Dashboard;
