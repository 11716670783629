import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "../src/logins.css";
import {API_CALL} from "./constant";
 

const Login = ({ onLogin }) => { 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [rsesponsee, setResponsee] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    
    useEffect(() => {
      //  const justLoggedOut = sessionStorage.getItem("justLoggedOut");
        const justLoggedOut = localStorage.getItem("justLoggedOut");
        if (justLoggedOut === "true") {
            const sessionMessage = "Logout Successful!";
            if (sessionMessage) {
                setSuccessMessage(sessionMessage);
                const timer = setTimeout(() => {
                    setSuccessMessage(null);
                    // sessionStorage.removeItem("justLoggedOut");
                    localStorage.removeItem("justLoggedOut");
                }, 2000);
                return () => clearTimeout(timer);
            }
        }
    });

        const handleSubmit = async (e) => {
            e.preventDefault(); 
        
            try {
                const response = await fetch(`${API_CALL}login`, {   
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, password })
                });
        
                if (response.ok == true) {
                    console.log('response');
                    console.log(response);
                     
                    const data = await response.json();
                    const token = data.token;
                    localStorage.setItem('token', token); 
                    setIsLoggedIn(true);
                    console.log(localStorage.getItem('token', token)); 

                    //sessionStorage.setItem('justLoggedIn', 'true');
                    localStorage.setItem('justLoggedIn', 'true');
                   console.log(localStorage.getItem('justLoggedIn', 'true'));
                 
                    navigate('/dashboard');


                } else {
                    const data = await response.json();
                    setResponsee(data.error);
                    console.log(response);
                }
            } catch (error) {
                console.log("Error logging in:", error);
            }
        };
            

    return (
        <div>
             {successMessage && (
            <div className="alertlogout">
                {successMessage}
            </div>
        )}
       
        <div className="login-container">
        <form onSubmit={handleSubmit}>
            <h1>Welcome!!</h1>
            <br />
            <h3>Login Here</h3>
            <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email Id"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Your Password"
            />
            <br />
            <button class="button1" type="submit">Submit</button>
            {rsesponsee && <p style={{ color: 'red' }}>{rsesponsee}</p>}
        </form>
        </div>
        </div>
    );
};

export default Login;
