import React from 'react';
import { Route, Navigate } from 'react-router-dom';

// const PrivateRoute = ({ isAuthenticated, children }) => {
//   return isAuthenticated ? children : <Navigate to="/login" />;
// };

const PrivateRoute = ({ isAuthenticated, children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
};


export default PrivateRoute;



