import React, { useState } from 'react';
import './App.css';
import Nav from './nav';

import Login from './login';
import Dashboard from './dashboard';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Commd5com from './commd5com';
import Commplain from './complain';
import Convertplainmd5 from './convertplainmd5';
import Merge from './mergecsvfiles';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };


  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>



          <Route path="/" element={<Login />} />

          <Route
            path="/login"
            element={<Login onLogin={() => setIsAuthenticated(true)} />}
          />



          <Route
            path="/dashboard"
            element={<PrivateRoute isAuthenticated={isAuthenticated}><Dashboard onLogout={handleLogout} /></PrivateRoute>}
          />
          <Route
            path="/convertplainmd5"
            element={<PrivateRoute isAuthenticated={isAuthenticated}><Convertplainmd5 onLogout={handleLogout} /></PrivateRoute>}
          />
          <Route
            path="/commd5com"
            element={<PrivateRoute isAuthenticated={isAuthenticated}><Commd5com onLogout={handleLogout} /></PrivateRoute>}
          />
          <Route
            path="/complain"
            element={<PrivateRoute isAuthenticated={isAuthenticated}><Commplain onLogout={handleLogout} /></PrivateRoute>}
          />

          <Route
            path="/merge"
            element={<PrivateRoute isAuthenticated={isAuthenticated}><Merge onLogout={handleLogout} /></PrivateRoute>}
          />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
