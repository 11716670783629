import React  from "react";
import { Link } from "react-router-dom"; 

const Nav=()=>{
    return(
      
        <div></div>
    )
}

export default Nav;