import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import {API_CALL} from "./constant";

const Commplain = () => {

    let uploadError=false;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [flag, setFlag] = useState(queryParams.get("flag"));
    const navigate = useNavigate();
    
    const [successMessage, setSuccessMessage] = useState(null);

    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFileName2, setSelectedFileName2] = useState("");
    const [showDownloadButtons, setShowDownloadButtons] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);

    
    const handleNaviPlain = () =>{
        navigate('/dashboard');
    }
   
    const handleNaviPlainMd5Comp = () =>{
        navigate('/commd5com');
    }

    const handleLogout = () => {
        // sessionStorage.setItem('justLoggedOut', 'true');
        localStorage.setItem('justLoggedOut', 'true');
        navigate('/login');
    };
    

    const handleFileChange = (event) => {
        
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setSelectedFileName(selectedFile ? selectedFile.name : "");
    
    };
    const handleFileChange2 = (event) => {
        
        const selectedFile2 = event.target.files[0];
        setFile2(selectedFile2);
        setSelectedFileName2(selectedFile2 ? selectedFile2.name : "");
    
    };

    const uploadFileHandler = async () => {

        const plainFile = document.getElementById('uploadingfile').files[0];
        const md5File = document.getElementById('uploadingmd5file').files[0];
        console.log(plainFile);
        if (!plainFile || !md5File) {
            alert('Please select both files.');
            return;
        }
    
        setShowProgressBar(true);
        
        const formData = new FormData();
        formData.append('plainFile', plainFile);
        formData.append('plainFile2', md5File);
        console.log(formData);
        
            const ajax = new XMLHttpRequest();
            ajax.upload.addEventListener('progress', progressHandler, false);
           // ajax.addEventListener('load', completeHandler, false);
            ajax.addEventListener('error', errorHandler, false);
            ajax.addEventListener('abort', abortHandler, false);
            ajax.open('POST', `${API_CALL}dashboard/comPlainPlain`);
        
            ajax.onload = function() {
                if (ajax.status === 200) {
                    completeHandler();
                } else {
                    errorHandler();
                }
            };

            try {
            ajax.send(formData);
        } catch (error) {
            console.error('Error uploading files:', error.message);
            alert('An error occurred while uploading the files.');
        }
    };
    


    const progressHandler = (event) => {
        const percent = (event.loaded / event.total) * 100;
        setProgress(Math.round(percent));
    };
    
    const completeHandler = (event) => {
        if (!uploadError) {
            setShowProgressBar(false);
            setShowDownloadButtons(true);
            alert('File uploaded successfully.');
        }
    };
    
    const errorHandler = (event) => {
        alert('Upload failed.');
        uploadError = true;
        setShowDownloadButtons(false);
    };
    
    const abortHandler = (event) => {
        alert('Upload aborted.');
    };

const downloadMd5Files = async() =>{
    try {
        const response = await fetch(`${API_CALL}dashboard/plainDownloadMd5`);
        
        if (!response.ok) {
            throw new Error('Failed to download file.');
        }
        
        const blob = await response.blob();
        
        const currentDate = new Date().toISOString().slice(0,10);
        const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
        const fileName = `Compareplainplain-emailmd5-${currentDate}-${currentTime}.csv`;


        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; 
        a.click();
        
        window.URL.revokeObjectURL(url);
        
        alert('File downloaded successfully.');
    } catch (error) {
        console.error('Error downloading file:', error.message);
        alert('An error occurred while downloading the file.');
    }

};

    const downloadEmailFiles = async () => {
        try {
            const response = await fetch(`${API_CALL}dashboard/plainDownload`);
            
            if (!response.ok) {
                throw new Error('Failed to download file.');
            }
            
            const blob = await response.blob();
            
            const currentDate = new Date().toISOString().slice(0,10);
            const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
            const fileName = `Compareplainplain-email-${currentDate}-${currentTime}.csv`;


            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; 
            a.click();
          
            window.URL.revokeObjectURL(url);
            
            alert('File downloaded successfully.');
        } catch (error) {
            console.error('Error downloading file:', error.message);
            alert('An error occurred while downloading the file.');
        }
    };
    

    
    const handleDeleteData = async () => {
        try {
            const response = await fetch(`${API_CALL}dashboard/deleteData`, {
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error('Failed to delete data.');
            }

            alert('Data deleted successfully.');
        } catch (error) {
            console.error('Error deleting data:', error.message);
            alert('An error occurred while deleting the data.');
        }
    };

    const SampleCSVDownload = () => {
        const sampleData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(`email\nSample1@gmail.com\nSample2@gmail.com\n`);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', sampleData);
        downloadLink.setAttribute('download', 'sample.csv');
        downloadLink.click();
    };
    const SampleCSVDownloadMd5 = () => {
        const sampleData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(`a130870ccaa6bb8f250dcbec777ed7df\n3f009d72559f51e7e454b16e5d0687a1\n638475f48f62dc9d3137063b1b2c7057\n`);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', sampleData);
        downloadLink.setAttribute('download', 'sample.csv');
        downloadLink.click();
    };
    return (
        
        <div>
       
        {successMessage && (
            <div className="alert">
                {successMessage}
            </div>
        )}

        <div className="container">
            <div className="log">
                <div>
                <a className="abtn" onClick={handleNaviPlain} >Back</a>
                 
                </div>
                <div ><h2>Compare Plain-Plain</h2></div>
             
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
                        <a href="#" className="btn-logout" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i></a>
            </div>

            <div className="divborder">
                <div className="grid-container">
                    <div className="grid-item1">Step :1</div>
                    <div className="grid-item2">
                    <p><a onClick={handleDeleteData} className="btn-download">Clear Data</a></p>
                    </div>
                    <div className="grid-item"></div>
                    <div className="grid-item4">
                        <p>Step: 2</p>
                    </div>
                    <div className="grid-item5">
                        <p>Files Supported: CSV</p>
                        <label htmlFor="uploadingfile" className="btn btn-primary btn-file">Select Plain File</label>
                        <input
                                    type="file"
                                    id="uploadingfile"
                                    onChange={handleFileChange}
                                    required
                                    hidden
                                />
                       
                        {selectedFileName && <p> {selectedFileName}</p>}
                    </div>
                    <div className="grid-item6">
                        <p><a href="#" onClick={SampleCSVDownload}>Sample CSV</a></p>
                    </div>
                    <div className="grid-item4">
                        <p>Step: 3</p>
                    </div>
                    <div className="grid-item5">
                        <p>Files Supported: CSV</p>
                        <label htmlFor="uploadingmd5file" className="btn btn-primary btn-file">Select Plain File</label>
                      
                        <input
                                    type="file"
                                    id="uploadingmd5file"
                                    onChange={handleFileChange2}
                                    required
                                    hidden
                                />
                        {selectedFileName2 && <p> {selectedFileName2}</p>}
                    </div>
                    <div className="grid-item6">
                    <p><a href="#" onClick={SampleCSVDownload}>Sample CSV</a></p>
                    </div>
                    <div className="grid-item1">Step :4 </div>
                    <div className="grid-item">
                        <button className="btn btn-primary" type="button" name="btnSubmit" onClick={uploadFileHandler}>Upload File</button>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </div>

            <div className="form-group" style={{ padding: '5px' }}>
                <div className="progress" id="progressDiv" style={{ display: 'none' }}>
                    <progress id="progressBar" value="0" max="100" style={{ width: '100%', height: '1.2rem' }}></progress>
                </div>
            </div>

            <div className="form-group">
                <h3 id="status" style={{ color: '#000', fontSize: '18px' }}></h3>
                <p id="uploaded_progress" style={{ color: '#000' }}></p>
            </div>

            <div className="DownloadArea">
            {showDownloadButtons && (
                <div>
                    <label>Emails :  </label>
                    <button className="btn btn-downloadlink" onClick={downloadEmailFiles}>Download</button>
                    <br />
                    <br />
                    <label>Email and Md5:  </label>
                    <button className="btn btn-downloadlink" onClick={downloadMd5Files}>Download</button>
                </div>
            )}
        </div>
        </div>

        
    </div>
);
};


export default Commplain;
