import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import "./dashboard.css";
import { useLocation, useHistory } from "react-router-dom";
import {API_CALL} from "./constant";


const Convertplainmd5 = () => {

    let uploadError = false; 
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [flag, setFlag] = useState(queryParams.get("flag"));
    const navigate = useNavigate();
    
    const [successMessage, setSuccessMessage] = useState(null);

    const [file, setFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [showDownloadButtons, setShowDownloadButtons] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);

    

    useEffect(() => {
    // const justLoggedIn = sessionStorage.getItem("justLoggedIn");
    const justLoggedIn = localStorage.getItem("justLoggedIn");
    if (justLoggedIn === "true") {
        const sessionMessage = "Login Successful!";
        if (sessionMessage) {
            setSuccessMessage(sessionMessage);
            const timer = setTimeout(() => {
                setSuccessMessage(null);
                // sessionStorage.removeItem("justLoggedIn");
                localStorage.removeItem("justLoggedIn");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }
});

    const handleLogout = () => {
        // sessionStorage.setItem('justLoggedOut', 'true');
        localStorage.setItem('justLoggedOut', 'true');
        navigate('/login');
    };
    
    const handleNaviCompPlain = () => {
        navigate('/dashboard');
    }

    const handleNaviCompMd5 = () => {
        navigate('/Commd5com');
    }

    const handleFileChange = (event) => {
        
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setSelectedFileName(selectedFile ? selectedFile.name : "");
    
    };

    const uploadFileHandler = async () => {
        if (!file) {
            alert('Please select a file.');
            return;
        }
        setShowProgressBar(true);
        const formData = new FormData();
        formData.append('file', file);

      
           
    const ajax = new XMLHttpRequest();
    ajax.upload.addEventListener('progress', progressHandler, false);
    //ajax.addEventListener('load', completeHandler, false);
    ajax.addEventListener('error', errorHandler, false);
    ajax.addEventListener('abort', abortHandler, false);

    ajax.open('POST',`${API_CALL}dashboard/upload`);

    ajax.onload = function() {
        if (ajax.status === 200) {
            completeHandler();
        } else {
            errorHandler();
        }
    };

    
    try{
    ajax.send(formData);
        } catch (error) {
            console.log('Error uploading file:', error.message);
            alert('An error occurred while uploading the file.');
        }
    };

    const progressHandler = (event) => {
        const percent = (event.loaded / event.total) * 100;
        setProgress(Math.round(percent));
    };
    
    const completeHandler = (event) => {
        if (!uploadError) {
            setShowProgressBar(false);
            setShowDownloadButtons(true);
            alert('File uploaded successfully.');
        }
    };
    
    const errorHandler = (event) => {
        alert('Upload failed.');
        uploadError = true;
        setShowDownloadButtons(false);
    };
    
    const abortHandler = (event) => {
        alert('Upload aborted.');
    };

const downloadMd5Files = async() =>{
    try {
        const response = await fetch(`${API_CALL}dashboard/downloadMd5`);
        
        if (!response.ok) {
            throw new Error('Failed to download file.');
        }
        
        const blob = await response.blob();
        
        const currentDate = new Date().toISOString().slice(0,10);
        const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
        const fileName = `Convertplainmd5-emailmd5-${currentDate}-${currentTime}.csv`;



        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; 
        a.click();
        
        window.URL.revokeObjectURL(url);
        
        alert('File downloaded successfully.');
    } catch (error) {
        console.error('Error downloading file:', error.message);
        alert('An error occurred while downloading the file.');
    }

};

    const downloadEmailFiles = async () => {
        try {
            const response = await fetch(`${API_CALL}dashboard/download`);
            
            if (!response.ok) {
                throw new Error('Failed to download file.');
            }
            
            const blob = await response.blob();
            
            const currentDate = new Date().toISOString().slice(0,10);
            const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
            const fileName = `Convertplainmd5-email-${currentDate}-${currentTime}.csv`;


            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; 
            a.click();
         
            window.URL.revokeObjectURL(url);
            
            alert('File downloaded successfully.');
        } catch (error) {
            console.error('Error downloading file:', error.message);
            alert('An error occurred while downloading the file.');
        }
    };
    

    const handleDeleteData = async () => {
        try {
            const response = await fetch(`${API_CALL}dashboard/deleteData`, {
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error('Failed to delete data.');
            }

            alert('Data deleted successfully.');
        } catch (error) {
            console.error('Error deleting data:', error.message);
            alert('An error occurred while deleting the data.');
        }
    };

    const SampleCSVDownload = () => {
        const sampleData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(`email\nSample1@gmail.com\nSample2@gmail.com\n`);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', sampleData);
        downloadLink.setAttribute('download', 'sample.csv');
        downloadLink.click();
    };

    return (
        
        <div>
        {successMessage && (
            <div className="alert">
                {successMessage}
            </div>
        )}

    

        <div className="container">
                <div className="log">
                    <div>
                      
                        <a className="abtn"onClick={handleNaviCompPlain} >Back</a>
                    </div>
            <div ><h2>Convert Plain-MD5</h2></div>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
                    <a href="#" className="btn-logout" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i></a>

                </div>

                <div className="divborder">
                    <div className="grid-container">
                        <div className="grid-item1">Step :1</div>

                        <div className="grid-item2">
                            <p><a onClick={handleDeleteData} className="btn-download">Clear Data</a></p>
                        </div>

                        <div className="grid-item"></div>

                        <div className="grid-item4">
                            <p>Step: 2</p>
                        </div>

                        <div className="grid-item5">
                            <p>Files Supported: CSV</p>
                            <label htmlFor="uploadingfile" className="btn btn-primary btn-file">Select File</label>
                            <input
                                type="file"
                                id="uploadingfile"
                                onChange={handleFileChange}
                                required
                                hidden
                            />
                            <div id="fileList" className="fileList">
                            {selectedFileName && <p> {selectedFileName}</p>}
                        </div>
                        </div>

                        <div className="grid-item6">
                        
                        <p><a href="#" onClick={SampleCSVDownload}>Sample CSV</a></p>
                        </div>
                        <div className="grid-item1">Step :3</div>

                        <div className="grid-item">
                            
                            <button
                                className="btn btn-primary"
                                type="button"
                                id="btnSubmit"
                                name="btnSubmit"
                                onClick={uploadFileHandler}
                            >
                                UPLOAD FILE
                            </button>
                        </div>

                        <div className="grid-item"></div>

                        
                    </div>
                </div>

<div className="progressarea">{showProgressBar && (
                <div className="form-group" style={{ padding: '15px' }}>
        <div className="progress" id="progressDiv" style={{ display: 'block' }}>
        <progress id="progressBar" value={progress} max="100" style={{ width: '100%', height: '1.2rem' }}></progress>
        </div>
    </div>
)}{showProgressBar && (
    <div className="form-group">
        <h3 id="status" style={{ color: '#000', fontSize: '18px' }}></h3>
        <p id="uploaded_progress" style={{ color: '#000' }}>Progress: {progress}%</p>
    </div>
)}
    </div>

<div className="DownloadArea">
                {showDownloadButtons && (
    <div>
        <label>Emails :  </label>
        <button
            className="btn btn-downloadlink"
            onClick={downloadEmailFiles}
        >
            Download
        </button>
    <br></br>
    <br></br>
        <label>Email and Md5:  </label>
        <button
            className="btn btn-downloadlink"
            onClick={downloadMd5Files}
        >
            Download
        </button>
    </div>
)}

</div>
        </div>
        </div>
    );
};


export default Convertplainmd5;
